/* global gtag */
import { all, select, takeEvery } from 'redux-saga/effects';

export const trackEvent = ({ payload }) => {
    gtag('event', payload[0], {
        event_info: (payload[1] && payload[1].replace(/\s+/g, '')) || null,
        page_referrer: document.referrer,
        page_location: window.location.href,
    });
};

export function trackCheckoutEvent({ payload }) {
    gtag('event', payload.event, {
        items: [
            {
                item_id: payload.upc,
                item_name: payload.name,
            },
        ],
    });
}

export function viewPromotion({ payload }) {
    const id = payload.id || 'Missing Promo Code';
    const name = payload.name || 'Missing Event Info';

    gtag('event', 'view_promotion', {
        creative_slot: payload.placement || null,
        promotion_id: id,
        promotion_name: name,
        event_info: name,
        items: [
            {
                item_id: id,
                item_name: name,
            },
        ],
    });
}

export function* setUser({ payload }) {
    const ga4_site_id = yield select(state => state.ga4_site_id);

    gtag('config', ga4_site_id, {
        cookie_domain: '.tarot.com',
        user_id: payload || undefined,
        linker: {
            domains: ['tarot.com', 'dailyhoroscope.com', 'numerology.com', 'twittascope.com', 'zappallas-usa.com'],
        },
    });
}

export function* root() {
    yield all([
        takeEvery('ga:set-user', setUser),
        takeEvery('ga:event', trackEvent),
        takeEvery('ga:view_promotion', viewPromotion),
        takeEvery('ga:checkout_event', trackCheckoutEvent),
    ]);
}
